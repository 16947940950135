import MainView from '../../views/mainView/MainView';
import Services from '../../views/services/Services';
import Opinions from '../../views/opinions/Opinions';
import Gallery from '../../views/gallery/Gallery';
import AboutMe from '../../views/aboutMe/AboutMe';
import Contact from '../../views/contact/Contact';
import NotFound from '../../views/notFound/NotFound';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import './App.css';
import DetailedGallery from '../../views/detailedGallery/DetailedGallery';
import {AnimatePresence} from "framer-motion";
import NavigationBar from '../navigationBar/NavigationBar';


function App() {

    const location = useLocation();

    return (
        <div className="App">
            <NavigationBar/>

            <div className="vanilla-background">
                <AnimatePresence>
                    <Switch location={location} key={location.key}>
                        <Route exact path="/services" component={Services}/>
                        <Route exact path="/opinions" component={Opinions}/>
                        <Route exact path="/gallery" component={Gallery}/>
                        <Route exact path="/gallery/:id" component={DetailedGallery}/>
                        <Route exact path="/about-me" component={AboutMe}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/" component={MainView}/>
                        <Route exact path="/not-found" component={NotFound}/>
                        <Redirect to="/not-found"/>
                    </Switch>
                </AnimatePresence>
            </div>
        </div>
    );
}

export default App;