import {Component} from 'react';
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem, Row} from 'reactstrap';

import './DetailedGalleryCarousel.css';

class DetailedGalleryCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animating: false,
            activeIndex: props.activeIndex,
            items: props.items
        }
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setActiveIndex(nextIndex);
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setActiveIndex(nextIndex);
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setActiveIndex(newIndex);
    }

    setActiveIndex(index) {
        this.setState(
            {activeIndex: index}
        );
    }

    setAnimating(animate) {
        this.setState(
            {animating: animate}
        );
    }

    render() {
        const carouselItems = this.state.items
            .map(image => {
                return this.renderCarouselItem(image)
            });

        return (
                <Row>
                    <Carousel activeIndex={this.state.activeIndex} next={this.next} previous={this.previous} className="gallery-carousel-img">
                        <CarouselIndicators items={this.state.items} activeIndex={this.state.activeIndex}
                                            onClickHandler={this.goToIndex}/>
                        {carouselItems}
                        <CarouselControl direction="prev" onClickHandler={this.previous}/>
                        <CarouselControl direction="next" onClickHandler={this.next}/>
                    </Carousel>
                </Row>
        );
    }


    renderCarouselItem(item) {
        return (
            <CarouselItem onExiting={() => this.setAnimating(true)} onExited={() => this.setAnimating(false)}
                          key={item.src}>
                <img src={item.src} alt={item.alt} className="img-fluid carousel-img"/>
            </CarouselItem>
        );
    }

}

export default DetailedGalleryCarousel;
