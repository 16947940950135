import {Component} from 'react';
import DetailedGalleryCarousel from '../../components/detailedGalleryCarousel/DetailedGalleryCarousel';
import Footer from '../../components/footer/Footer';
import './DetailedGallery.css';
import {Redirect} from 'react-router-dom';
import {GALLERY} from '../../data/GalleryData';
import {Container, Row} from "reactstrap";

class DetailedGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            weddingId: parseInt(this.props.match.params.id),
            selectedImage: parseInt(new URLSearchParams(this.props.location.search).get("selectedImage"))
        }
    }

    render() {

        return this.validateInformation()
            ? this.renderDetailedGallery()
            : this.redirectInvalidCall()

    }

    validateInformation() {
        return GALLERY.map(gallery => gallery.id)
            .includes(this.state.weddingId);
    }

    redirectInvalidCall() {
        return (
            <Redirect to="/not-found"/>
        );
    }

    renderDetailedGallery() {

        const weddingInformation = GALLERY.find(wedding => wedding.id === this.state.weddingId)

        return (
            <Container>
                <Row>
                    <div className="col-auto">
                        <p className="title">{weddingInformation.title}</p>
                    </div>
                </Row>
                <DetailedGalleryCarousel activeIndex={this.state.selectedImage} items={weddingInformation.images}/>
                <Footer/>
            </Container>
        );
    }


}

export default DetailedGallery;
