import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {Container, Row} from "reactstrap";
import {motion} from "framer-motion";
import logo from "./img/Logo-largo-dorado.jpg";
import logoSm from "./img/Logo-largo-dorado-sm.jpg";

ReactDOM.render(
    <React.StrictMode>
        <Container>
            <Row className="justify-content-center">
                <a href="/" className="col-12 d-none d-lg-block">
                    <motion.img src={logo} className="navigation-logo img-fluid" alt="Bella del Forest"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: 2}}/>
                </a>
                <a href="/" className="col-12 d-lg-none">
                    <motion.img src={logoSm} className="navigation-logo-small img-fluid" alt="Bella del Forest"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: 2}}/>
                </a>
            </Row>
        </Container>
        <Router>
            <App/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
