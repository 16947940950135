import about_me from '../img/about-me/About-me.jpg';
import formation from '../img/about-me/Formation.jpg';


export const ABOUT_ME = [
    {
        src: about_me,
        title: "Hola bellos, soy Helena!",
        alt: "Hola, soy Helena!",
        description: <div>
            <p>Para que me puedas conocer un poco mejor, te diría que soy una amante de las conversaciones intensas e interminables con un café.</p>
            <p>Amo leer libros interesantes arropada en mantas a la luz de unas velas encendidas, y en mis noches de capricho, no falla nunca un buen sushi.</p>
            <p>Me encanta decorar espacios, y en mi casa nunca faltan unas buenas lucecitas que le den un aire acogedor.</p>
        </div>
    },
    {
        src: formation,
        title: "Formación",
        alt: "Formación",
        description: <div>
            <p>Periodista y amante de la comunicación.</p>
            <p>Master en diseño gráfico que me ayuda a transformar las ideas de mis <i>bellos del forest</i> y hacerlo realidad.</p>
            <p>La coordinación de oficinas me mostró parte de este mundo que me apasiona, empezando por la coordinación y gestión de eventos corporativos. </p>
        </div>
    }
];
