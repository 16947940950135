import picture1 from './photos/1.jpg';
import picture2 from './photos/2.jpg';
import picture3 from './photos/3.jpg';
import picture4 from './photos/4.jpg';
import picture5 from './photos/5.jpg';
import picture6 from './photos/6.jpg';
import picture7 from './photos/7.jpg';
import picture8 from './photos/8.jpg';
import opinionPortrait from "./photos/opinionPortrait.jpg";


export const WEDDING_OPINION_00 = {
    src: opinionPortrait,
    alt: "Opinión de Juanlu & Sarai",
    couple: "Juanlu & Sarai",
    description: <div>
        <p>
            Helena ha sido un pilar esencial en la preparación de nuestra boda. Desde el primer momento supimos que era
            la persona más indicada para acompañarnos y guiarnos en todo lo que necesitábamos para un día tan
            especial.
        </p>
        <p>
            Estábamos muy perdidos y desde la primera reunión que hicimos juntos vimos cómo encaminar la boda y
            descubrimos nuestros gustos. Sobre ello, Helena fue trabajando minuciosamente marcando un ritmo y unos
            tiempos esenciales para que no hubiera problemas.
        </p>
        <p>
            En la recta final su trabajo fue excepcional, contactando con todos los proveedores e implicándose al 100%
            para que el timing se cumpliera. Evitó una mal sorpresa de última hora de la que los novios ni nos
            enteramos, lo gestionó todo con mucha profesionalidad.
        </p>
        <p>
            Durante la boda estuvo pendiente en cada momento de que todo saliera según lo previsto, y lo que más nos
            gustó, podíamos contar con ella porque siempre estaba ahí.
        </p>
        <p>
            Pasados nueve meses desde nuestra boda, somos muy felices de decir que fue fantástica y que elegir a Helena
            ¡Fue todo un acierto!
        </p>

    </div>
}

export const WEDDING_PHOTOS_00 = {
    id: 0,
    title: "Juanlu & Sarai",
    date: "Agosto 2021",
    previewImages: [
        {
            id: 0,
            src: picture1,
            alt: "Juanlu & Sarai: Foto 1",
            location: "A"
        },
        {
            id: 1,
            src: picture2,
            alt: "Juanlu & Sarai: Foto 2",
            location: "B"
        },
        {
            id: 2,
            src: picture3,
            alt: "Juanlu & Sarai: Foto 3",
            location: "C"
        },
        {
            id: 3,
            src: picture4,
            alt: "Juanlu & Sarai: Foto 4",
            location: "D"
        },
        {
            id: 4,
            src: picture5,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 5,
            src: picture6,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 6,
            src: picture7,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 7,
            src: picture8,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        }
    ],
    images: [
        {
            id: 0,
            src: picture1,
            alt: "Juanlu & Sarai: Foto 1",
            location: "A"
        },
        {
            id: 1,
            src: picture2,
            alt: "Juanlu & Sarai: Foto 2",
            location: "B"
        },
        {
            id: 2,
            src: picture3,
            alt: "Juanlu & Sarai: Foto 3",
            location: "C"
        },
        {
            id: 3,
            src: picture4,
            alt: "Juanlu & Sarai: Foto 4",
            location: "D"
        },
        {
            id: 4,
            src: picture5,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 5,
            src: picture6,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 6,
            src: picture7,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        },
        {
            id: 7,
            src: picture8,
            alt: "Juanlu & Sarai: Foto 5",
            location: "E"
        }
    ]
};
