import all_included_icon from '../img/services/All-included.jpg';
import b_day_coordination from '../img/services/B-day-coordination.jpg';
import decoration from '../img/services/Decoration.jpg';
import online_service from '../img/services/Online-service.jpg';


export const SERVICES_PROVIDED = [
    {
        src: all_included_icon,
        alt: "Organización integral",
        title: "Organización Integral",
        description: <div>
            <p>Empezaremos desde el minuto cero con un asesoramiento y planificación personalizada, adaptada a cada uno
                de mis <i>bellos del forest</i>, haciendo que cada evento sea único e irrepetible.</p>
            <p>Además, si lo deseáis también podemos acompañaros durante la planificación de vuestra luna de miel para
                que sólo os preocupéis de disfrutar.</p></div>
        ,
    },
    {
        src: b_day_coordination,
        alt: "Coordinación del día B",
        title: "Coordinación del día B",
        description: <div>
            <p>Si tenéis todo listo para el gran día, y queréis vivir el momento con los vuestros, este servicio es lo que estáis buscando.</p>
            <p>Trabajaremos mano a mano con todos vuestros proveedores para que la coordinación y los tiempos se cumplan a la perfección. Para ello, creamos timings personalizados por proveedor para que se puedan centrar plenamente en su trabajo para que todo salga perfecto.</p>
        </div>
    },
    {
        src: decoration,
        alt: "Decoración",
        title: "Decoración",
        description: <div>
            <p>Si para vosotros la organización no es un problema, pero estáis buscando formas originales de darle vida a vuestro día, podéis contar con nuestro servicio de decoración.</p>
            <p>Comenzaremos con una reunión para conoceros mejor, y os propondremos diversas decoraciones basadas en vuestros gustos, adaptándonos plenamente a vuestro presupuesto.</p>
            <p>Contamos con una carta de proveedores de confianza que harán elevar vuestro evento a lo más alto.</p>
        </div>
    },
    {
        src: online_service,
        alt: "Consultas Online",
        title: "Consultas Online",
        description: <div>
            <p>Si habéis llegado a un punto en el que no sabéis muy bien por donde seguir, o necesitáis cualquier tipo de consejo, las consultas Online son vuestra solución.</p>
            <p>Trataremos en detalle cualquier inquietud que tengáis mediante reuniones Online desde la comodidad de vuestros hogares.</p>
        </div>
    }
];
