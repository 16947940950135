import {motion} from 'framer-motion';
import {Component} from 'react';
import {Row} from 'reactstrap';


import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './InformationCard.css';
import {cardVariant, highlightVariant} from "../../motion/variants.js";
import {
    RESPONSIVE_CARD_IMAGE_LEFT,
    RESPONSIVE_CARD_IMAGE_RIGHT,
    RESPONSIVE_CARD_TEXT_LEFT,
    RESPONSIVE_CARD_TEXT_RIGHT
} from "../../global/constants/CardsConstants";

class InformationCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: props.position,
            imgSource: props.properties.src,
            imgAlt: props.properties.alt,
            title: props.properties.title,
            description: props.properties.description,
            orientation: props.orientation,
            displayedInPage: props.displayInPage
        }
    }

    render() {

        const responsivePropertiesImage = (this.state.orientation === "left")
            ? RESPONSIVE_CARD_IMAGE_RIGHT
            : RESPONSIVE_CARD_IMAGE_LEFT;

        const responsivePropertiesTexts = (this.state.orientation === "left")
            ? RESPONSIVE_CARD_TEXT_RIGHT
            : RESPONSIVE_CARD_TEXT_LEFT;

        return (
            <motion.div
                variants={cardVariant}
                initial="hidden"
                animate="visible"
                transition={{duration: 0.75, delay: this.state.position / 3}}>
                <motion.div
                    variants={highlightVariant}
                    initial="initialColor"
                    whileHover="highlightEffect">
                    <Row className="section justify-content-center align-items-center">
                        <div className={responsivePropertiesImage}>
                            <img src={this.state.imgSource} alt={this.state.imgAlt} className="img-fluid shadow-image"/>
                        </div>
                        <div className={responsivePropertiesTexts}>
                            <h5 className={"sub-category-underlined align-center"}>{this.state.title}</h5>
                            <div className={"content align-left"}>{this.state.description}</div>
                            {
                                "services" === this.state.displayedInPage &&
                                <div className={"align-left"}>
                                    <a href="/contact" className={"link-text"}>Me interesa!</a>
                                </div>

                            }
                        </div>
                    </Row>
                </motion.div>
            </motion.div>
        );
    }

}

export default InformationCard;
