import {Component} from 'react';
import {Container, Row} from 'reactstrap';
import Footer from '../../components/footer/Footer';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './Gallery.css';
import {pageTransitionVariant} from "../../motion/variants.js";
import {motion} from 'framer-motion';
import {GALLERY} from "../../data/GalleryData";


class Gallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            fullScreenModal: true
        }
    }

    toggleModalVisibility() {
        console.log("Click: " + this.state.isVisible);
        this.setState(state => ({isVisible: !state.isVisible}));
    }

    render() {
        return (
            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
            >
                <Container>
                    <Row>
                        <div className="col-auto">
                            <p className="title">Mis bellos del Forest</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-auto">
                            <p className="headline"> Aquí podrás encontrar muchas fotos sobre las bodas que hemos hecho
                                realidad</p>
                        </div>
                    </Row>
                    {this.renderPreviews()}
                </Container>
                <Footer/>
            </motion.div>
        );
    }

    renderPreviews() {
        return GALLERY.map(wedding => {
            return (
                <Container>
                    <Row>
                        <p className="col-12 wedding-title align-left">{wedding.title} {wedding.date}</p>
                    </Row>
                    <Row id={"wedding-collage-" + wedding.id}>
                        {wedding.previewImages.map(previewImage => {
                            return (
                                <a href={"/gallery/" + wedding.id + "?selectedImage=" + previewImage.id}
                                   className="wedding-column col-3">
                                    <img src={previewImage.src} alt={previewImage.alt} className="img-fluid wedding-img"
                                         onClick={() => this.toggleModalVisibility()}/>
                                </a>
                            );
                        })}
                    </Row>
                </Container>
            );
        });
    }
}

export default Gallery;
