import {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import './Footer.css';
import '../../global/styles/GlobalTextStyles.css';
import {SOCIAL_MEDIA} from "../../data/SocialMedia";
import {socialMediaVariant} from "../../motion/variants";
import {motion} from 'framer-motion';

class Footer extends Component {

    render() {
        const mediaLinks = SOCIAL_MEDIA.map((mediaLink, index) => {
            return this.renderMediaLink(mediaLink, index);
        });

        return (
            <footer className="footer">
                <Container>
                    <Row>
                        <div className="col-12">
                            {mediaLinks}
                        </div>
                    </Row>
                    <Row className="justify-content-center copyright">
                        <Col>
                            <p>© Copyright 2022 Bella del Forest</p>
                            <p>Todos los derechos reservados</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }

    renderMediaLink(item) {
        return (
            <motion.a
                href={item.url} target="_blank" rel="noreferrer">
                <motion.img src={item.icon} alt={item.name} className="social-icon"
                            variants={socialMediaVariant}
                            initial="initial"
                            animate="expand"/>
            </motion.a>
        );
    }
}

export default Footer;
