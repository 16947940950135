import {Component} from 'react';
import {Carousel, CarouselItem,} from 'reactstrap';

import './MainCarousel.css';
import {MAIN_CAROUSEL} from "../../data/MainCarousel";


class MainCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animating: false,
            activeIndex: 0,
            items: MAIN_CAROUSEL
        }
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setActiveIndex(nextIndex);
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setActiveIndex(nextIndex);
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setActiveIndex(newIndex);
    }

    setActiveIndex(index) {
        this.setState(
            {activeIndex: index}
        );
    }

    setAnimating(animate) {
        this.setState(
            {animating: animate}
        );
    }

    render() {
        const carouselItems = this.state.items
            .map(image => {
                return this.renderCarouselItem(image)
            });

        return (
            <Carousel activeIndex={this.state.activeIndex} next={this.next} previous={this.previous}>
                {carouselItems}
            </Carousel>
        );
    }


    renderCarouselItem(item) {
        return (
            <CarouselItem onExiting={() => this.setAnimating(true)} onExited={() => this.setAnimating(false)}
                          key={item.src}>
                <img src={item.src} alt={item.altText} className="img-fluid main-carousel-img"/>
            </CarouselItem>
        );
    }

}

export default MainCarousel;
