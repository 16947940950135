import {Component} from 'react';
import {Container, Row} from 'reactstrap';
import Footer from '../../components/footer/Footer';

import '../../global/styles/GlobalStyles.css';
import './NotFound.css';


class NotFound extends Component {


    render() {
        return (
            <div className="vanilla-background">
                <Container>
                    <Row>
                        <div className="col-auto">
                            <p className="title">Página no encontrada</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-auto">
                            <p className="headline text-center"> Lo sentimos, pero parece que la página que andas buscando no existe :(</p>
                        </div>
                    </Row>
                </Container>

                <Footer/>
            </div>
        );
    }

}

export default NotFound;
