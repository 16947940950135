export const commonAnimations = {
    hidden: {
        opacity: 0
    },
    visibleFast: {
        opacity: 1,
        transition: {duration: 0.5}
    },
    visibleSlow: {
        opacity: 1,
        transition: {duration: 1}
    },
    expand: {
        scale: 1.2
    }
}

export const navigationVariant = {
    hoverShadow: {
        textShadow: "0px 0px 4px rgb(200,200,200)"
    },
    shrink: {
        scale: 0.8
    }
}

export const pageTransitionVariant = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {duration: 1}
    },
    exit: {
        x: "-100vw",
        transition: {ease: 'easeInOut'}
    }
}

export const cardVariant = {
    hidden: {
        opacity: 0,
        y: 500
    },
    visible: {
        opacity: 1,
        y: 0,
        background: "#fafafa"
    }
}

export const highlightVariant = {
    initialColor: {
        background: "#fafafa"
    },
    highlightEffect: {
        transition: {duration: 0.2},
        background: "#FFFFFF",
        boxShadow: "0px 0px 2px rgb(200,115,30)",
        borderRadius: "2em"
    }
}

export const contactFieldVariant = {
    initialLeft: {
        opacity: 0,
        x: -500
    },
    initialRight: {
        opacity: 0,
        x: 500
    },
    initialBottom: {
        opacity: 0,
        y: 500
    },
    visible: {
        opacity: 1,
        x: 0,
        y: 0
    },
    highlightEffect: {
        background: "#FFFDFB",
        boxShadow: "0px 0px 2px rgb(200,150,105)",
        borderRadius: "2em"
    }
}

export const socialMediaVariant = {
    initial: {
        scale: 0
    },
    expand: {
        scale: 1,
        transition: {duration: 0.5, delay: 0.5}
    }
}