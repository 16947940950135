import {Component} from 'react';
import {Container, Row} from 'reactstrap';
import Footer from '../../components/footer/Footer';
import {SERVICES_PROVIDED} from '../../data/ServicesProvided.js';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './Services.css';
import {motion} from 'framer-motion';
import {pageTransitionVariant} from '../../motion/variants.js';
import InformationCard from "../../components/informationCard/InformationCard";


class Services extends Component {


    render() {
        let orientation = "left";

        const services = SERVICES_PROVIDED.map((service, index) => {
            orientation = (orientation === "left") ? "right" : "left";
            return (<InformationCard properties={service} position={index} orientation={orientation} displayInPage="services"/>)
        });

        return (

            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
            >
                <Container>
                    <Row>
                        <div className="col-auto">
                            <p className="title">Servicios que ofrecemos</p>
                        </div>
                    </Row>
                    <Row className="headline">
                        <div className="col-auto">
                            <p className="text-center"> Desde <i>Bella del Forest</i> queremos que tu día sea
                                perfecto.
                                Por ello te ofrecemos una amplia gama de servicios personalizados para que elijas el qué
                                más se adapte a tus necesidades.
                            </p>
                            <p className="text-center">
                                Nos encargaremos de que vuestro día sea como siempre soñasteis, tanto si es una boda
                                íntima con los tuyos, o una celebración por todo lo alto.</p>
                        </div>
                    </Row>
                </Container>

                <Container>
                    <Row className="justify-content-center">

                        {services}

                    </Row>
                </Container>
                <Footer/>
            </motion.div>
        );
    }

}

export default Services;
