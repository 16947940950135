import {Component} from 'react';
import Footer from '../../components/footer/Footer';
import MainCarousel from '../../components/mainCarousel/MainCarousel';

import '../../global/styles/GlobalStyles.css';
import './MainView.css';
import {motion} from 'framer-motion';
import {pageTransitionVariant} from "../../motion/variants.js";

class MainView extends Component {


    render() {
        return (
            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
            >
                <MainCarousel/>
                <Footer/>
            </motion.div>
        );
    }

}

export default MainView;
