import {Component} from 'react';
import {Container, Row} from 'reactstrap';
import Footer from '../../components/footer/Footer';
import {OPINIONS_RECEIVED} from '../../data/OpinionsReceived.js';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './Opinions.css';
import {motion} from 'framer-motion';
import {pageTransitionVariant} from '../../motion/variants.js';
import OpinionCard from "../../components/OpinionCard/OpinionCard";


class Opinions extends Component {


    render() {
        let orientation = "left";

        const opinions = OPINIONS_RECEIVED
            .map((service, index) => {
                orientation = (orientation === "left") ? "right" : "left";
                return (<OpinionCard properties={service} position={index} orientation={orientation}/>)
            });

        return (

            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
            >
                <Container>
                    <Row>
                        <div className="col-auto">
                            <p className="title">Opiniones de nuestros Bellos del Forest</p>
                        </div>
                    </Row>
                </Container>

                <Container>
                    <Row className="justify-content-center">

                        {opinions}

                    </Row>
                </Container>
                <Footer/>
            </motion.div>
        );
    }

}

export default Opinions;
