import {motion} from 'framer-motion';
import {Component} from 'react';
import {Row} from 'reactstrap';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './OpinionCard.css';
import {cardVariant, highlightVariant} from "../../motion/variants.js";
import {
    RESPONSIVE_CARD_IMAGE_LEFT,
    RESPONSIVE_CARD_IMAGE_RIGHT,
    RESPONSIVE_CARD_TEXT_LEFT,
    RESPONSIVE_CARD_TEXT_RIGHT
} from "../../global/constants/CardsConstants";

class OpinionCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: props.position,
            imgSource: props.properties.src,
            imgAlt: props.properties.alt,
            couple: props.properties.couple,
            description: props.properties.description,
            orientation: props.orientation
        }
    }

    render() {

        const responsivePropertiesImage = (this.state.orientation === "left")
            ? RESPONSIVE_CARD_IMAGE_LEFT
            : RESPONSIVE_CARD_IMAGE_RIGHT;

        const responsivePropertiesTexts = (this.state.orientation === "left")
            ? RESPONSIVE_CARD_TEXT_LEFT
            : RESPONSIVE_CARD_TEXT_RIGHT;

        return (
            <motion.div
                variants={cardVariant}
                initial="hidden"
                animate="visible"
                transition={{duration: 0.75, delay: this.state.position / 3}}>
                <motion.div
                    variants={highlightVariant}
                    initial="initialColor"
                    whileHover="highlightEffect">
                    <Row className="section justify-content-center align-items-center">
                        <div className={responsivePropertiesImage}>
                            <img src={this.state.imgSource} alt={this.state.imgAlt} className="img-fluid shadow-image"/>
                            <h7 className={"sub-category align-center"}>{this.state.couple}</h7>
                        </div>
                        <div className={responsivePropertiesTexts}>
                            <div className={"content align-left"}>{this.state.description}</div>
                        </div>
                    </Row>
                </motion.div>
            </motion.div>
        );
    }

}

export default OpinionCard;
