import instagram_icon from "../img/footer/instagram.png";
import linkedin_icon from "../img/footer/linkedin.png";


export const SOCIAL_MEDIA = [
    {
        name: "Instagram",
        url: "https://www.instagram.com/belladelforest/",
        icon: instagram_icon
    },
    {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/helenacarrascomorera",
        icon: linkedin_icon
    }
]
