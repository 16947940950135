export const NAVIGATION_OPTIONS = [
    {
        id: 1,
        name: "SERVICIOS",
        url: "/services"
    },
    {
        id: 2,
        name: "OPINIONES",
        url: "/opinions"
    },
    {
        id: 3,
        name: "GALERÍA",
        url: "/gallery"
    },
    {
        id: 4,
        name: "SOBRE MÍ",
        url: "/about-me"
    },
    {
        id: 5,
        name: "CONTACTO",
        url: "/contact"
    }
];
