import {Component} from 'react';
import Footer from '../../components/footer/Footer';
import {ABOUT_ME} from '../../data/AboutMe';
import {motion} from 'framer-motion';

import '../../global/styles/GlobalStyles.css';
import './AboutMe.css';
import {pageTransitionVariant} from "../../motion/variants.js";
import {Container} from 'reactstrap';
import InformationCard from "../../components/informationCard/InformationCard";

class AboutMe extends Component {


    render() {

        let orientation = "right";
        const aboutMeEntries = ABOUT_ME.map((aboutMe, index) => {
            orientation = (orientation === "left") ? "right" : "left";
            return (<InformationCard properties={aboutMe} position={index} orientation={orientation} displayInPage="aboutMe"/>)
        });

        return (

            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                <Container className="spaced-top">
                    {aboutMeEntries}
                </Container>
                <Footer/>
            </motion.div>
        );
    }

}

export default AboutMe;
