import {motion} from 'framer-motion';
import {Component} from 'react';
import {Button, Container, Form, FormGroup, Row} from 'reactstrap';
import Footer from '../../components/footer/Footer';
import {contactFieldVariant, pageTransitionVariant} from '../../motion/variants.js';
import stars from '../../img/icons/stars.png';
import coffee from '../../img/icons/coffee.png';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './Contact.css';
import {CONFIGURED_CONTACT_FIELDS} from "../../data/ContactFields";

class Contact extends Component {


    render() {

        const CONTACT_FIELDS = CONFIGURED_CONTACT_FIELDS.map((field, index) => {
            return this.renderContactItem(field, index);
        });

        return (
            <motion.div className="vanilla-background"
                        variants={pageTransitionVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                <Container>
                    <Row>
                        <div className="col-auto">
                            <p className="title">¿Un Café?</p>
                        </div>
                    </Row>
                    <Row className="justify-content-left">
                        <div className="col-auto">
                            <p className="headline align-left">
                                <p>
                                    Si habéis llegado a esta página, es probable que tengáis en mente algo grande que
                                    celebrar. &nbsp;&nbsp;<img className="icon" src={stars} alt="stars icon"/>
                                </p><p>
                                Posiblemente la idea del día perfecto esté en vuestra cabeza, pero no conseguís
                                darle forma, o no sabéis por dónde empezar.
                            </p><p>
                                Quizá lo que os falta es tiempo para llevar a cabo toda la planificación, organización y
                                gestión que vuestro día necesita.
                            </p><p>
                                O puede que necesitéis ayuda para diseñar vuestra visión decorativa que deje sin
                                palabras a vuestros invitados.
                            </p>
                            </p>
                            <p className="headline align-left">
                                Entonces... ¿un café?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img className="icon" src={coffee} alt="coffee icon"/>
                            </p>
                        </div>
                    </Row>
                </Container>

                <Container>
                    <Form action="https://formspree.io/f/xyybojye" method="POST">
                        <Container>
                            {CONTACT_FIELDS}
                            {this.renderSubmitButton(CONTACT_FIELDS.length)}
                        </Container>
                    </Form>

                </Container>
                <Footer/>
            </motion.div>
        );
    }

    renderContactItem(item, index) {
        const responsiveTextConfiguration = "col-md-2 text-sm-center text-md-right";
        const responsiveContainerConfiguration = "col-md-10 col-lg-6";

        return (

            <FormGroup className="row align-items-center justify-content-center form-field">
                <motion.div className={responsiveTextConfiguration}
                            variants={contactFieldVariant}
                            initial="initialLeft"
                            animate="visible"
                            transition={{duration: 0.5, delay: index / 5}}>
                    {item.htmlTextTag}
                </motion.div>
                <motion.div className={responsiveContainerConfiguration}
                            variants={contactFieldVariant}
                            initial="initialRight"
                            animate="visible"
                            transition={{duration: 0.5, delay: index / 5}}>
                    {item.htmlContainerTag}
                </motion.div>
            </FormGroup>
        );
    }

    renderSubmitButton(index) {
        return (
            <FormGroup key={"submitButton"}
                       className="row align-items-center justify-content-center">
                <motion.div className="col-12 col-md-5 col-lg-3"
                            variants={contactFieldVariant}
                            initial="initialBottom"
                            animate="visible"
                            transition={{duration: 0.5, delay: index / 5}}>
                    <Button className="form-button">Enviar</Button>
                </motion.div>
            </FormGroup>
        );
    }
}

export default Contact;
