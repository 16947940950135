import hamburger from '../../img/navbar/hamburguer.png';
import goldenSplatters from '../../img/navbar/GoldenSplatters.png';
import {Component} from 'react';
import {Collapse, Container, Nav, NavbarToggler, Row} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {motion} from 'framer-motion';

import '../../global/styles/GlobalStyles.css';
import '../../global/styles/GlobalTextStyles.css';
import './NavigationBar.css';
import {NAVIGATION_OPTIONS} from "../../data/NavigationOptions";
import {navigationVariant} from "../../motion/variants";


class NavigationBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavigationBar = this.toggleNavigationBar.bind(this);

        this.state = {
            isNavigationOpen: false,
            activeOption: this.props.option
        };
    }

    toggleNavigationBar() {
        this.setState({
            isNavigationOpen: !this.state.isNavigationOpen
        });
    }

    render() {
        const menuOptions = NAVIGATION_OPTIONS
            .map((option => this.renderMenuOption(option)
            ));

        return (
            <div className="navigation-bar white-background navbar-expand-xl">
                <Container>
                    <Row>
                        <NavbarToggler className="col-sm-12 d-xl-none" onClick={this.toggleNavigationBar}>
                            <img src={hamburger} alt="Menu" className="navigation-button"/>
                        </NavbarToggler>
                        <Collapse isOpen={this.state.isNavigationOpen} navbar className="justify-content-center">
                            <Nav className="navigation-items justify-content-center col-lg-12" navbar>
                                {menuOptions}
                            </Nav>
                        </Collapse>
                    </Row>
                </Container>
            </div>
        );
    }

    renderMenuOption(item) {

        return (
            <motion.div
                className={"col-lg-12 col-xl-2 justify-content-center align-items-center navigation-item "}
                variants={[navigationVariant]}
                whileHover="hoverShadow"
                whileTap="shrink">

                <NavLink
                    className="navigation-link"
                    to={item.url}
                    onClick={() => this.onMenuOptionSelect(item.id)}>
                    {item.name}
                    {item.id === this.state.activeOption &&
                    <img className="splatters" src={goldenSplatters} alt="Golden splatters"/>
                    }
                </NavLink>
            </motion.div>);
    }


    onMenuOptionSelect(optionId) {
        this.setState({activeOption: optionId})
    }
}

export default NavigationBar;
