import {Input, Label} from "reactstrap";

export const CONFIGURED_CONTACT_FIELDS = [
        {
            htmlTextTag: <Label for="name" className="form-text">Nombre</Label>,
            htmlContainerTag: <Input type="text" className="form-control form-value" id="name"
                                     name="name" placeholder="Nombre"/>,
        },
        {
            htmlTextTag: <Label for="email" className="form-text">Email</Label>,
            htmlContainerTag: <Input type="text" className="form-control form-value" id="email"
                                     name="email" placeholder="Email"/>
        },
        {
            htmlTextTag: <Label for="message" className="form-text">Mensaje</Label>,
            htmlContainerTag: <Input type="textarea" className="form-control form-value"
                                     id="message" name="message" rows="10"
                                     placeholder="Escribe aquí lo que quieras"/>
        }
    ]
;